<template>
    <div>
        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-flex>
                <a class="mr-3"
                   @click="emitFetch">
                    <v-icon size="20">{{ ICONS.REFRESH }}</v-icon>
                    {{ $t('buttons.refresh_list') }}
                </a>
            </v-flex>
        </v-layout>
        <v-data-table
            :headers="getHeaders()"
            :hide-default-header="$vuetify.breakpoint.smAndUp"
            disable-sort
            :loading="loader"
            :items="sestLuvWeeksReports"
            disable-pagination
            hide-default-footer
            item-key="id"
            class="elevation-1">
            <template v-slot:header="{ props }"
                      v-if="$vuetify.breakpoint.smAndUp">
                <thead>
                <tr>
                    <th v-for="(headerItem, index) in props.headers"
                        :key="index">
                        <SortableHeader :header-item="headerItem"
                                        @sort="sort"></SortableHeader>
                    </th>
                </tr>
                </thead>
            </template>
            <template v-for="week in getHeaders().slice(2)" v-slot:[`item.${week.value}`]="{ item }">
                {{ getWeekData(item.weeks, week.number) }}
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'InspectionsWeekCompletionsTable',
    components: { SortableHeader, Pagination },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        sestLuvWeeksReports: {
            type: Array,
            'default': () => []
        },
        sestLuvWeeksList: {
            type: Array,
            'default': () => []
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        ICONS
    }),
    watch: {
        sestLuvWeeksReportsTotalAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            }
        }
    },
    methods: {
        getHeaders () {
            const headers = [
                {
                    text: this.$t('sest_luv.report.object'),
                    value: 'object_name'
                },
                {
                    text: this.$t('sest_luv.report.line'),
                    value: 'point_name'
                }
            ];
            if (this.sestLuvWeeksList) {
                this.sestLuvWeeksList.forEach(i => {
                    headers.push({
                        text: `${this.$t('sest_luv.report.week')} ${i}`,
                        value: `week_${i}`,
                        number: i
                    });
                });
            }
            return headers;
        },

        getWeekData (item, weekNr) {
            const weekData = Object.entries(item).filter(week => parseInt(week[0]) === weekNr);
            return weekData.length ? Math.round(weekData[0][1].completion_rate * 100) + '%' : '-';
        },
        emitFetch () {
            this.$emit('fetch');
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            const val = event;
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'asc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        }
    }
};
</script>

<style scoped>

</style>
