<template>
    <div class="pa-4 mb-7">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('sest_luv.title_weeks') }}</h1>
        <FiltersBar :withSearch="false"
                    ref="filtersBar"></FiltersBar>
        <InspectionsWeekCompletionsTable :loader="loader"
                                         :sestLuvWeeksReports=sestLuvWeeksReports
                                         :sestLuvWeeksList=sestLuvWeeksList
                                         @fetch="fetchSestLuvWeeksReports"
                                         @sort="sortingColumn = $event"
                                         @change-page="changePage($event)"
                                         @change-amount="changeAmount($event)"
        ></InspectionsWeekCompletionsTable>
    </div>
</template>

<script>
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debounce from 'lodash.debounce';
import debouncedYears from '@/mixins/debounced/debouncedYears';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import filtering from '@/mixins/filtering';
import { mapState } from 'vuex';
import YearPickerFilter from '@/models/filters/yearPickerFilter';
import InspectionsWeekCompletionsTable from '@/components/inspections/InspectionsWeekCompletionsTable';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'SestLuvReportsWeeks',
    components: {
        FiltersBar,
        InspectionsWeekCompletionsTable
    },
    mixins: [
        filtering,
        debouncedLocations,
        debouncedYears,
        paginationSettings
    ],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        loader: false
    }),
    watch: {
        sortingColumn: {
            handler () {
                this.fetchSestLuvWeeksReports();
            },
            deep: true
        }
    },
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapState('microserviceReports', [
            'sestLuvWeeksReports',
            'sestLuvWeeksReportsTotalAmount',
            'sestLuvWeeksList'
        ]),
        currentYear () {
            return new Date().getFullYear();
        },
        weeksFilter () {
            const weeks = [];
            for (let i = 0; i < 52; i++) {
                weeks.push({
                    text: `Week ${i + 1}`,
                    value: i + 1
                });
            }
            return weeks;
        },
        filtersConfig () {
            return [
                new YearPickerFilter({
                    model: FILTERS.CHOSEN_YEAR,
                    label: this.$t('labels.year'),
                    max: `${this.currentYear}-NaN-NaN`,
                    referralCallback: this.referralYearCallback
                }),
                new AutocompleteFilter({
                    model: FILTERS.CHOSEN_WEEK,
                    items: this.weeksFilter,
                    multiple: true,
                    disabled: true,
                    label: 'weeks',
                    successMessages: this.$t('messages.choose_year')
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (this.filters[f] && this.filters[f].length > 0) {
                    params[f] = this.filters[f];
                }
                if (this.filters[f] && f === FILTERS.CHOSEN_WEEK) {
                    const state = this.filters[f];
                    const weeks = [];
                    state.forEach(week => {
                        weeks.push(week);
                    });
                    params[f] = weeks;
                }
            });
            return params;
        },
        fetchSestLuvWeeksReports () {
            this.loader = true;
            const params = {
                limit: this.pagination.amount,
                ...this.generateFilters()
            };

            if (this.pagination.page === 1) {
                params.offset = 0;
            } else {
                params.offset = (this.pagination.page - 1) * this.pagination.amount;
            }

            this.$store.dispatch('microserviceReports/getSestLuvWeeksReports', {
                params: params
            }).finally(() => {
                this.loader = false;
            });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchSestLuvWeeksReports();
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.fetchSestLuvWeeksReports();
        }
    },
    created () {
        this.debouncedFetchSestLuvWeeksReports = debounce(this.fetchSestLuvWeeksReports, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchSestLuvWeeksReports);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
            vm.$store.dispatch('locations/getLocations')
                .then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.LOCATION_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }

};
</script>

<style scoped>

</style>
