import { FILTERS } from '@/constants/filtersModelNames';

export default {
    methods: {
        referralYearCallback (newVal) {
            const filterPropertyName = this.isModal ? 'modalFiltersConfig' : 'filtersConfig';
            const mutationTitle = this.isModal ? 'filters/SET_MODAL_CONFIG' : 'filters/SET_CONFIG';
            if (newVal && newVal.length > 0) {
                this[filterPropertyName].find(el => el.model === FILTERS.CHOSEN_WEEK).disabled = false;
                this[filterPropertyName].find(el => el.model === FILTERS.CHOSEN_WEEK).successMessages = '';
            } else {
                this[filterPropertyName].find(el => el.model === FILTERS.CHOSEN_WEEK).disabled = true;
                this[filterPropertyName].find(el => el.model === FILTERS.CHOSEN_WEEK).successMessages = this.$t('messages.choose_customer');
            }
            this.$store.commit(mutationTitle, this[filterPropertyName]);
        }
    }
};
